<template>
  <div>
    <component
      :is="tag"
      v-if="currentWord"
      :class="currentWord.classes"
      @click="link(currentWord.link)"
      >{{ currentWord.text }}</component
    >
  </div>
</template>

<script>
export default {
  props: {
    words: {
      type: Array,
      required: true,
    },
    tag: {
      type: String,
      default: "span",
    },
  },
  data() {
    return {
      currentWord: {},
      currentIndex: 0,
      state: null,
      duration: 2500,
    };
  },
  watch: {
    currentIndex() {
      const vm = this;
      this.currentWord.classes = this.currentWord.classes.replace(
        "fadeIn",
        "fadeOut"
      );
      setTimeout(() => {
        if (typeof vm.words[vm.currentIndex] === "string") {
          vm.currentWord = {
            text: vm.words[vm.currentIndex],
            classes: "fadeIn",
            duration: 1650,
          };
        } else {
          vm.currentWord = { ...vm.words[vm.currentIndex] };
          vm.currentWord.classes += " fadeIn";
        }
      }, 500);
    },
  },
  created() {
    if (typeof this.words[this.currentIndex] === "string") {
      this.currentWord = {
        text: this.words[this.currentIndex],
        classes: "fadeIn",
        duration: 1650,
      };
    } else {
      this.currentWord = { ...this.words[this.currentIndex] };
      this.currentWord.classes += " fadeIn";
    }
  },
  mounted() {
    this.action();
  },
  beforeDestroy() {
    clearTimeout(this.state);
  },
  methods: {
    link(link) {
      if (link) {
        window.location.href = link;
      }
    },
    action() {
      const vm = this;
      this.state = setTimeout(() => {
        if (vm.currentIndex < vm.words.length - 1) {
          vm.currentIndex++;
        } else {
          vm.currentIndex = 0;
        }
        this.duration = vm.words[vm.currentIndex].duration || 1650;
        this.action();
      }, vm.duration * 1 + 1000);
    },
  },
};
</script>

<style scoped>
.text-shadow-none {
  text-shadow: none;
}
.text-lg {
  font-size: 24px;
}
.fadeIn {
  animation-name: fadeIn;
  animation-duration: 0.5s;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-100%);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fadeOut {
  animation-name: fadeOut;
  animation-duration: 0.5s;
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translateY(0);
  }

  to {
    opacity: 0;
    transform: translateY(100%);
  }
}

body {
  font-family: "arial";
}

h1 {
  text-align: center;
  font-size: 2rem;
}

.inlineBlock {
  display: inline-block;
}

.green {
  color: green;
}

.red {
  color: red;
}

.blue {
  color: blue;
}

.strong {
  font-weight: bold;
}

.italic {
  font-style: italic;
}

.underlined {
  text-decoration: underline;
}

.html5 {
  color: #e94e2f;
}
.css3-alt {
  color: #0f76b9;
}
.js {
  color: #dab92c;
}
.bootstrap {
  color: #7a54b3;
}
.php {
  color: #8a93bd;
}
.node-js {
  color: #83cd29;
}
.vuejs {
  color: #41b883;
}
</style>